import './style';
import './app';
import 'swiper/scss';
import Swiper, { Navigation, Pagination } from 'swiper';

const swiperServices = new Swiper('.swiperServices', {
  modules: [Navigation, Pagination],
  slidesPerView: 1.2,
  spaceBetween: 10,
  centeredSlides: true,
  loop: true,
  pagination: {
    el: '.swiper-pagination',
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2.1,
      spaceBetween: 35,
    },
    1100: {
      slidesPerView: 3,
      spaceBetween: 50,
      centeredSlides: false,
    },
  },
});
